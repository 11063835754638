<template>
  <div v-if="invoiceData">
    <KTPortlet
      :title="`Khách hàng: ${invoiceData.customer}`"
      headSize="md"
      class="h-100 col-lg-6 mx-auto"
    >
      <template v-slot:body>
        <div class="d-flex justify-content-between">
          <p>
            <b>NGÀY XUẤT HOÁ ĐƠN</b><br />
            {{ invoiceDate }}
          </p>
          <p>
            <b>KHÁCH HÀNG</b><br />
            {{ invoiceData.customerName }}
          </p>
        </div>
        <div v-for="item in invoiceData.sessions" :key="item._id">
          <hr />
          <p>
            <b>{{ item.name }} ({{ item.total }})</b>
          </p>
          <p class="text-underline">DỊCH VỤ: ({{ item.subTotal }})</p>
          <div v-for="(product, index) in item.orderProduct" :key="index">
            <p class="d-flex justify-content-between mb-0">
              {{ product.name }} <span>{{ product.price }}</span>
            </p>
          </div>
          <p class="text-underline" v-if="item.promos.length">
            GIẢM GIÁ: ({{ item.discountTotal }})
          </p>
          <div v-for="(promo, index) in item.promos" :key="index">
            <p class="d-flex justify-content-between">
              {{ promo.name }} <span>{{ promo.currencyValue }}</span>
            </p>
          </div>
          <p class="text-underline" v-if="item.tip">TIP: ({{ item.tip }})</p>
        </div>

        <div>
          <hr />
          <p><b>THÔNG TIN THANH TOÁN</b></p>
          <div class="d-flex justify-content-between">
            <p class="text-underline no-outline" v-b-toggle="invoiceData._id">
              <b>GIFT CARD:</b> <span>({{ gCTotal }})</span>
            </p>
            <p class="text-underline">
              <b>TRẢ THẺ:</b>
              <span>({{ invoiceData.paymentMethods.card }})</span>
            </p>
            <p class="text-underline">
              <b>TIỀN MẶT:</b>
              <span>({{ invoiceData.paymentMethods.cash }})</span>
            </p>
          </div>
          <b-collapse :id="invoiceData._id" v-if="gCTotal">
            <hr />
            <p class="d-flex justify-content-between mb-0 text-underline">
              <span><b>GiftCode</b></span> <span><b>Giá trị</b></span>
            </p>
            <div
              v-for="(gc, index) in invoiceData.paymentMethods.giftCard"
              :key="index"
            >
              <p class="d-flex justify-content-between mb-0">
                {{ gc.code }} <span>{{ gc.value }}</span>
              </p>
            </div>
          </b-collapse>
        </div>
      </template>
    </KTPortlet>
  </div>
</template>

<style lang="scss">
.currency-input {
  text-align: center;
  width: 100%;
  padding-right: 5px;
  outline: none !important;
  border: none !important;
  background-color: transparent;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.payment-input {
  width: 25vw;
  margin-left: auto;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>

<script>
import KTPortlet from "@/components/Portlet.vue";
import { getPaymentDetail, getInvoice } from "@/api/invoice";
import Loader from "@/common/loader";
import moment from "moment";

export default {
  name: "Edit",
  components: {
    KTPortlet
  },
  data() {
    return {
      invoiceData: null,
      customerCode: ""
    };
  },
  mounted() {
    const invoiceId = this.$route.params.id;
    this.$store.dispatch("titleBreadcrumb", "Thông tin hóa đơn");
    if (invoiceId) {
      Loader.fire();
      getInvoice(invoiceId)
        .then(res => {
          this.invoiceData = res.data;
        })
        .finally(Loader.close);
    }
  },
  computed: {
    sessionProductFields() {
      return [
        { key: "name", label: "Dịch vụ" },
        {
          key: "price",
          label: "Doanh thu",
          class: "modal-table-cell text-right"
        }
      ];
    },

    sessionPromoFields() {
      return [
        { key: "name", label: "Giảm giá" },
        {
          key: "value",
          label: "Giá trị",
          class: "modal-table-cell text-right"
        }
      ];
    },

    invoicePromoFields() {
      return [
        { key: "name", label: "Giảm giá" },
        {
          key: "currencyValue",
          label: "Giá trị",
          class: "modal-table-cell text-right"
        }
      ];
    },

    totalInvoicePromo() {
      return this.invoiceData.discount.promotions.reduce(
        (total, promo) => (total += Math.ceil(promo.currencyValue)),
        0
      );
    },

    totalGiftCard() {
      return this.invoiceData.paymentMethods.giftCard.reduce(
        (total, g) => (total += g.value),
        0
      );
    },

    title() {
      return `MÃ HOÁ ĐƠN: ${this.invoiceData?.invoiceNumber || "xxxx-xxxx"}`;
    },

    sessionName() {
      return this.invoiceData
        ? this.invoiceData.sessions.map(s => s.name)?.join(", ")
        : "";
    },

    invoiceDate() {
      return this.invoiceData
        ? moment(this.invoiceData.sessions[0].purchasedDate).format(
            "DD/MM/YYYY - HH:mm:ss"
          )
        : "DD/MM/YYYY - HH:mm:ss";
    },

    gCTotal() {
      return this.invoiceData
        ? this.invoiceData.paymentMethods.giftCard.reduce(
            (t, g) => (t += g.value),
            0
          )
        : 0;
    }
  }
};
</script>
